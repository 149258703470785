import React from 'react'
import {GlobalContainer} from './container/GlobalContainer'
import styled from '@emotion/styled'
import {FormattedMessage, useIntl} from 'gatsby-plugin-intl'
import LinkedinIcon from '../images/linkedin.inline.svg'
import MailIcon from '../images/mail.inline.svg'
import {device} from '../devices'

const Footer = () => {
  const intl = useIntl()
  return (
    <FooterContainer>
      <GlobalContainer>
        <ContainerFooter>
          <BlocFooter>
            <Title>
              <FormattedMessage id="footer.about.about"/>
            </Title>
            <ListBloc>
              <li><FormattedMessage id="footer.about.terms"/></li>
              <li><FormattedMessage id="footer.about.contact"/></li>
              <li><FormattedMessage id="footer.about.blog"/></li>
            </ListBloc>
          </BlocFooter>
          <BlocFooter>
            <Title>
              <FormattedMessage id="footer.newsletter.newsletter"/>
            </Title>
            <ListBloc>
              <NewsleterInput placeholder={intl.formatMessage({id: "footer.newsletter.input"})}/>
              <SocialMediaContainer>
                <a href="https://fr.linkedin.com/company/jqtech"><LinkedinIcon/></a>
                <a href="mailto:jeff@jqtech.fr"><MailIcon/></a>
              </SocialMediaContainer>
            </ListBloc>
          </BlocFooter>
        </ContainerFooter>
      </GlobalContainer>
    </FooterContainer>
  )
}

export default Footer

const NewsleterInput = styled.input`
  width: 16.3vw;
  height: 2.7vw;
  object-fit: contain;
  border-radius: 2.7vw;
  box-shadow: inset 0 0 0.488vw 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border: none;
  padding: 0.5vw 0 0.195vw 1.270vw;
  border: 0.195vw solid transparent;
  
  &:focus {
    border: 0.195vw solid ${({theme}) => theme.primary};
    outline: none;
  }
  
  ::placeholder {
    opacity: 0.37;
    font-size: 1.074vw;
    font-weight: 500;
    letter-spacing: 0.018vw;
    color: #050d3e;
  }
  
  @media ${device.mobileL} {
    width: 83%;
    height: 40px;
    border-radius: 6px;
    box-shadow: inset 0 0 10px 0 rgba(0,0,0,0.16);
    padding: 0 10px;
    border: 2px solid transparent;
    margin-top:20px;
    font-size: 100%;
    
    ::placeholder {
      font-size: 100%;
    }
  }
`

const SocialMediaContainer = styled.div`
  display: flex;
  margin-top: 0.781vw;
  svg {
    margin-right: 0.977vw;
    cursor: pointer;
  }
  
  @media ${device.mobileL} {
    align-items: center;
    justify-content: center;
    
    svg {
      width: 58px;
      height: auto;
      margin-right: 20px;
      margin-top: 20px;
    }
  }
`

const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16.602vw;
  
  @media ${device.mobileL} {
    display:flex;
    flex-direction: column;
    height: auto;
  }
`
const ListBloc = styled.ul`
  width: 100%;
  margin-top: 1.5vw;
  
  li {
    font-size: 1.2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.022vw;
    color: #000000;
    line-height: 1.8vw;
  }
  
  @media ${device.mobileL} {
    text-align: center;
    li {
      font-size: 100%;
      line-height:normal;
    }
  }
`
const ContainerFooter = styled.div`
  display: flex;
  width:100%;
  justify-content: space-evenly;
  align-items: center;
  
  @media ${device.mobileL} {
    flex-direction:column;
  }
`
const BlocFooter = styled.div`
  display:flex;
  flex-direction: column;
  
  @media ${device.mobileL} {
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0;
  }
`

const Title = styled.h4`
  font-size: 1.6vw;
  font-weight: 800;
  color: ${({theme}) => theme.primary};
  
  @media ${device.mobileL} {
    font-size: 120%;
  }
`

