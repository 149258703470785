import React from 'react'
import PropTypes from 'prop-types'
import {graphql, useStaticQuery} from 'gatsby'
import {ThemeProvider} from 'emotion-theming'
import {css, Global} from '@emotion/core'
import emotionReset from 'emotion-reset'
import Header from './header'
import {theme} from './theme'
import Footer from './footer'


const Layout = ({children}) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      css={css`
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Global
        styles={css`
        ${emotionReset}
        *, *::after, *::before {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

  body, h1, h2, h3, h4, h5, h6, p, a, span, button {
    font-family: 'Poppins', sans-serif;
     -ms-text-size-adjust: 100%;
     -webkit-text-size-adjust: 100%;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
  }
  
  html, body {
    max-width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
  }
  a {
    text-decoration: none;
  }
      `}
      />
      <ThemeProvider theme={theme}>
        <Header siteTitle={data.site.siteMetadata.title}/>
        <main>{children}</main>
        <Footer/>
      </ThemeProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
