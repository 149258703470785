import styled from '@emotion/styled'
import {device} from '../../devices'

export const GlobalContainer = styled.div`
  width: 86.8%;
  max-width: 86.8%;
  align-self: center;
  position: relative;
  height:100%;
  
  @media ${device.mobileL} {
    max-width: 92%;
    width: 92%;
    height: auto;
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  @media${device.mobileL} {
      height: auto;
      min-height: 725px;
  }
`
