import React from 'react'
import styled from '@emotion/styled'
import {Link} from 'gatsby'
import {keyframes} from '@emotion/core'
import {device} from '../devices'

export const click = keyframes`
from {
  opacity: 1;
} 
to {
  opacity:0;
}
`

export const Logo = ({isOpen}) => (
  <Link to="/" style={{textDecoration: `none`}}><LogoContainer isOpen={isOpen}><p>JQ TECH</p><span/></LogoContainer></Link>
)

const LogoContainer = styled.div`
  font-weight: 900;
  font-size: 2.3vw;
  letter-spacing: 0.02vw;
  line-height: 1.7vw;
  margin: 0;
  display: flex;
  align-items: flex-end;
  
    
  p {
    color: ${({theme, isOpen}) => isOpen ? theme.white : theme.black};
    z-index: 100001;
  }
  
  span {
    background-color: ${({theme, isOpen}) => isOpen ? theme.white : theme.primary};
    height: 0.586vw;
    width: 1.855vw;
    margin-left: 0.090vw;
    animation: ${click} .9s ease-in-out infinite;
    z-index: 100001;
  }
  
  @media ${device.mobileL} {
    font-size: 8.3vw;
    line-height: 8.6vw;
    
    span {
      height: 1.2vw;
      width: 3.8vw;
      animation: inherit;
    }
  }
`
