import PropTypes from 'prop-types'
import React, {useRef, useState} from 'react'
import {Logo} from './logo'
import styled from '@emotion/styled'
import {css} from '@emotion/core'
import {FormattedMessage, Link} from 'gatsby-plugin-intl'
import {GlobalContainer} from './container/GlobalContainer'
import {device} from '../devices'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <GlobalContainer>
      <HeaderContainer>
        <Logo isOpen={isOpen}/>
        <Menu/>
        <MenuMobile isOpen={isOpen} setIsOpen={setIsOpen} />
      </HeaderContainer>
    </GlobalContainer>
  )
}
const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}


const StyledBurger = styled.button`
  position: absolute;
  top: 5vw;
  right: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100001;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => open ? '#fff' : '#000'};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-of-type {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-of-type(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-of-type(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

const MenuListMobile = ({ open, setIsOpen }) => {
  return (
    <StyledMenu open={open}>
      <Link to="/paas" onClick={() => setIsOpen(false)}>
        PAAS
      </Link>
      <Link to="/softwares" onClick={() => setIsOpen(false)}>
        Softwares
      </Link>
      <a href="/en/#contact-us" onClick={() => setIsOpen(false)}>
        <span role="img" aria-label="contact">📩</span>
        Contact us
      </a>
    </StyledMenu>
  )
}

const MenuMobile = ({isOpen, setIsOpen}) => {
  const node = useRef()
  return (
    <div id="mobile-menu" ref={node}>
      <Burger open={isOpen} setOpen={setIsOpen}/>
      <MenuListMobile open={isOpen} setOpen={setIsOpen}/>
    </div>
  )
}

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  background: ${({ theme: {primary} }) => primary};
  height: 100vh;
  text-align: left;
  padding: 0;
  position: absolute;
  top: 0;
  left: -4vw;
  z-index: 10000;
  transition: transform 0.3s ease-in-out;

  @media ${device.mobileL} {
      width: 100vw;
    }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ${({ theme: {white} }) => white};
    text-decoration: none;
    transition: color 0.3s linear;

    @media ${device.mobileL} {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`

const Menu = () => {
  return (
    <ul css={css`display: flex;`} id="desktop-menu">
      <LinkNav activeStyle={{color: '#0000ff'}} to={`/paas/`}><ListItems><FormattedMessage
        id="paas"/></ListItems></LinkNav>
      <LinkNav activeStyle={{color: '#0000ff'}} to={`/softwares/`}><ListItems><FormattedMessage
        id="softwares"/></ListItems></LinkNav>
      <LinkNav activeStyle={{color: '#0000ff'}} to={`/#contact-us`}><ListItems><FormattedMessage
        id="contact_us"/></ListItems></LinkNav>
    </ul>
  )
}

const LinkNav = styled(Link)`
  color: ${({theme}) => theme.black};
`
const ListItems = styled.li`
  padding: 0 35px 0 0;
  font-size: 1.5vw;
  font-weight: 800;
  letter-spacing: 0.06vw;
  outline: none;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding-bottom: 10px;
  
  #mobile-menu {
    display:none;
  }
  
  @media ${device.mobileL} {
    #mobile-menu {
      display:flex;
    }
  
    #desktop-menu {
      display: none;
    }
  }
`
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
